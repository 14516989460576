import React, { useState, useRef } from "react"
import styled from "styled-components"
import { IoIosAdd } from "react-icons/io"
import { colors, H3Black, B1Black } from "../../styles/helpers"

const FaqItemStyled = styled.div`
  margin: 0;
  padding: 0 1rem 0 2.5rem;
  overflow: hidden;
  border-bottom: 0.2rem dotted ${colors.greyBrown};

  @media (min-width: 768px) {
    padding: 0 5rem;
  }

  .faqSingle__question {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2rem 0;
    background: ${colors.white};
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;

    p {
      ${H3Black}
      margin: 0;
      padding-left: 2rem;
    }
  }

  .icon {
    position: absolute;
    top: 0;
    left: -3rem;
    bottom: 0;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    margin: auto;
    transition: transform 0.6s ease;
    color: ${colors.colorSecondary};

    @media (min-width: 768px) {
      left: -5rem;
    }

    svg {
      display: block;
      width: 4rem;
      height: 4rem;
      transition: transform 0.6s ease;
      transform-origin: center;
    }
  }

  .rotate {
    svg {
      transform: rotate(300deg);
    }
  }

  .faqSingle__answer {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;

    p {
      ${B1Black};
      padding-left: 2rem;
    }
  }
`

const FaqItem = ({ question, answer }) => {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  const [setRotate, setRotateState] = useState("icon")
  const content = useRef(null)

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "")
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    )
    setRotateState(setActive === "active" ? "icon" : "icon rotate")
  }
  return (
    <FaqItemStyled>
      <button
        className={`faqSingle__question ${setActive}`}
        onClick={toggleAccordion}
      >
        <p>{question}</p>
        <span className={`${setRotate}`}>
          <IoIosAdd />
        </span>
      </button>
      <div
        className="faqSingle__answer"
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
      >
        <div dangerouslySetInnerHTML={{ __html: answer }} />
      </div>
    </FaqItemStyled>
  )
}

export default FaqItem
