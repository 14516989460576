import React from "react"
import styled from "styled-components"
import FaqItem from "./FaqItem"
import { standardWrapper, colors } from "../../styles/helpers"

const FaqsListSection = styled.div`
  position: relative;
  padding: 4rem 0;
  z-index: 250;

  .wrapper {
    ${standardWrapper};
    position: relative;
    background-color: ${colors.white};
    padding: 2rem 1rem;

    @media (min-width: 768px) {
      padding: 2rem;
    }

    @media (min-width: 1025px) {
      margin-top: -37.5rem;
    }

    .bgGraphic {
      position: absolute;
      top: 0;
      left: -100%;
      width: 200%;
      height: 100%;
      background-color: ${colors.white};
      z-index: -1;
    }
  }

  .faqsContainer {
    width: 100%;
    padding: 1.5rem;
    background-color: ${colors.white};

    @media (min-width: 768px) {
      padding: 5.5rem;
    }
  }
`

const FaqsList = ({ data }) => {
  const faqs = data.acf._coo_fali_faqs_list

  return (
    <FaqsListSection>
      <div className="wrapper">
        <div className="faqsContainer">
          {faqs.map((faq, index) => {
            return (
              <FaqItem
                key={index}
                question={faq.question}
                answer={faq.answer}
              />
            )
          })}
        </div>
        <div className="bgGraphic" />
      </div>
    </FaqsListSection>
  )
}

export default FaqsList
