import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Faqs/Hero"
import FaqsList from "../components/Faqs/FaqsList"

const Faqs = props => {
  const { seoInfo, hero, faqList } = props.data
  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <Hero hero={hero} />
      <FaqsList data={faqList} />
    </Layout>
  )
}

export const faqsQuery = graphql`
  query faqsPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    hero: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_hohr_bg {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hohr_title
        _coo_hohr_content
      }
    }

    faqList: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_fali_faqs_list {
          question
          answer
        }
      }
    }
  }
`

export default Faqs
